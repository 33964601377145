<template>
  <div class="noneData">
    <img src="@/assets/icon/nonedata.png" alt="" />
    <p class="title">{{ title }}</p>
    <p class="btn" @click="changePage" v-show="showBtn">{{ btnText }}</p>
  </div>
</template>
<script>
export default {
  name: "NoneData",
  props: {
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changePage() {
      this.$router.push("/home");
      this.$store.commit("changeTabPage", 1);
    },
  },
};
</script>
<style scoped>
.noneData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.noneData img {
  width: 1.82rem;
  height: 1.82rem;
  object-fit: cover;
}
.noneData .title {
  width: 100%;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #b1b1b3;
  margin: 0.24rem 0 0.16rem;
}
.btn {
  margin: 0 auto;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #ffffff;
  padding: 0.19rem .35rem;
  background: linear-gradient(90deg, #FE1112 0%, #E4022B 100%);
  border-radius: 0.28rem;
}
</style>