<template>
  <div id="informationPage">
    <topbar title="消息中心" bgColor="#f2f3f5"></topbar>
    <div class="infoList" v-if="list.length">
      <div class="infoItem" v-for="(item,index) in list" :key="index"  @click="changePage('infoDetail')">
        <div class="content">
          <img src="@/assets/icon/jitui.png" alt="" />
          <div>
            <p>
              消息標題消息標題消息標題消息標題消息標題消息標息標題消息標題消息標題消息標題消息標題消息標
            </p>
            <p>
              正文正文正文正文正文正文正文正文正文正文正文正文正文正息標題消息標題消息標題消息標題消息標題消息標
            </p>
            <p class="time">
              <span>06-06-2021 14:21</span>
              <span>瀏覽</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <none-data v-else v-show="true" title="暫無消息" :showBtn="false"></none-data>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import NoneData from "../../components/common/NoneData.vue"
export default {
  name: "Information",
  data(){
      return {
          list:[1],
      }
  },
  methods:{
    changePage(path){
      this.$router.push("/"+path)
    }
  },
  components: {
    Topbar,
    NoneData
  },
};
</script>
<style scoped>
#informationPage {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #f2f3f5;
}
.infoList {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
}
.infoItem {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.08rem;
  margin-bottom: .12rem;
}
.content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.content img {
  width: 0.8rem;
  height: 0.8rem;
  object-fit: cover;
  margin-right: 0.12rem;
}
.content div p {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #0a0c14;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.content div p:nth-child(2) {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #6b6e79;
  margin-top: 0.08rem;
}
.content div p.time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.14rem;
  color: #b1b1b3;
  margin-top: 0.12rem;
}
.content div p.time span:last-child {
  width: .46rem;
  height: .24rem;
  border: .01rem solid #e4022b;
  border-radius: .15rem;
  text-align: center;
  line-height: .26rem;
  color: #E4022B;
}
</style>